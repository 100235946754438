import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql, StaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Container, Section, H2, Separator } from './style'
import { useIntl } from 'gatsby-plugin-intl'

const StyledSection = styled(Section)`
  background-color: #f9f9f9;
`

const OfferHeader = styled.div`
  display: flex;
  flex-direction: row;
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
`

const Right = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`

const Paragraph = styled.p`
  align-self: center;
  padding: 1.75rem;
`

const OfferList = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;

  @media (min-width: 800px) {
    flex-direction: row;
  }
`

const OfferItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  background-color: #fff;
  flex-basis: 30%;
`

const Image = styled((props) => <GatsbyImage {...props} />)`
  max-width: 100%;
`

const StyledButton = styled.button`
  color: #fff;
  font-size: 0.75rem;
  font-weight: 300;
  width: max-content;
  height: max-content;
  padding: 0.9rem 1.2rem;
  border: none;
  border-radius: 0 20px;
  background-image: linear-gradient(60deg, #3567b7, rgba(53, 103, 183, 0.84));
  letter-spacing: 0.1em;
  position: relative;
  z-index: 1;
  transition: all 0.1s;

  &:before {
    border-radius: inherit;
    background-image: linear-gradient(
      270deg,
      #3567b7,
      rgba(53, 103, 183, 0.84)
    );
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    z-index: -1;
    transition: opacity 0.1s;
  }

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }

  &:active {
    opacity: 0.85;
    &:before {
      opacity: 1;
    }
  }
`

const Offer = () => {
  const intl = useIntl()
  const locale = intl.locale !== 'pl' ? `${intl.locale}` : 'pl'

  const offers = [
    {
      title_pl: 'Projekt i budowa maszyn',
      title_en: 'Design and construction of machines ',
    },
    {
      title_pl: 'Modernizacja mechaniczna i elektryczna maszyn',
      title_en: 'Mechanical and electrical modernization of machines',
    },
    {
      title_pl: 'Programowanie PLC',
      title_en: 'PLC programming',
    },
    {
      title_pl: 'Wykonanie komponentów mechanicznych',
      title_en: 'Execution of mechanical components',
    },
    {
      title_pl: 'Pomiary elektryczne maszyn i sieci elektrycznych',
      title_en: 'Electrical measurements of machines and electrical networks',
    },
    {
      title_pl: 'Digitalizacja schematów elektrycznych',
      title_en: 'Digitization of electrical diagrams',
    },
    {
      title_pl: 'Termowizja i serwis szaf elektrycznych',
      title_en: 'Thermovision and service of electrical cabinets',
    },
    {
      title_pl: 'Budowa szaf elektrycznych',
      title_en: 'Construction of electrical cabinets',
    },
    {
      title_pl: 'Specjalizacja: automatyzacja na komponentach Mitsubishi',
      title_en: 'Specialization: automation on Mitsubishi components',
    },
  ]

  const [offersToShow, setOffersToShow] = useState(offers.slice(0, 3))
  const [isShowingMore, setShowMore] = useState(false)

  const showMore = () => {
    isShowingMore
      ? setOffersToShow(offers.slice(0, 3))
      : setOffersToShow(offers)

    setShowMore(!isShowingMore)
  }

  const query = graphql`
    {
      image1: file(relativePath: { eq: "offer_1.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            transformOptions: { fit: COVER }
            layout: FULL_WIDTH
          )
        }
      }
      image2: file(relativePath: { eq: "offer_2.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            transformOptions: { fit: COVER }
            layout: FULL_WIDTH
          )
        }
      }
      image3: file(relativePath: { eq: "offer_3.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            transformOptions: { fit: COVER }
            layout: FULL_WIDTH
          )
        }
      }
      image4: file(relativePath: { eq: "offer_4.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            transformOptions: { fit: COVER }
            layout: FULL_WIDTH
          )
        }
      }
      image5: file(relativePath: { eq: "offer_5.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            transformOptions: { fit: COVER }
            layout: FULL_WIDTH
          )
        }
      }
      image6: file(relativePath: { eq: "offer_6.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            transformOptions: { fit: COVER }
            layout: FULL_WIDTH
          )
        }
      }
      image7: file(relativePath: { eq: "offer_7.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            transformOptions: { fit: COVER }
            layout: FULL_WIDTH
          )
        }
      }
      image8: file(relativePath: { eq: "offer_8.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            transformOptions: { fit: COVER }
            layout: FULL_WIDTH
          )
        }
      }
      image9: file(relativePath: { eq: "offer_9.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            transformOptions: { fit: COVER }
            layout: FULL_WIDTH
          )
        }
      }
    }
  `

  return (
    <StyledSection>
      <Container>
        <OfferHeader>
          <Left>
            <H2>{intl.formatMessage({ id: 'offer_title' })}</H2>
            <Separator />
          </Left>
          <Right>
            <StyledButton onClick={showMore}>
              {isShowingMore
                ? intl.formatMessage({ id: 'offer_button_less' })
                : intl.formatMessage({ id: 'offer_button_more' })}
            </StyledButton>
          </Right>
        </OfferHeader>
        <OfferList>
          {offersToShow.map((offer, index) => (
            <OfferItem key={index}>
              <StaticQuery query={query}>
                {(data) => <Image
                  image={
                    data[Object.keys(data)[index]].childImageSharp
                      .gatsbyImageData
                  }
                  alt=""
                />}
              </StaticQuery>
              <Paragraph>{offer[`title_${locale}`]}</Paragraph>
            </OfferItem>
          ))}
        </OfferList>
      </Container>
    </StyledSection>
  )
}

export default Offer
