import React from 'react'
import styled from 'styled-components'
import { Container, Section, H2, Separator, Button } from './style'
import { useIntl } from 'gatsby-plugin-intl'

const Paragraph = styled.p`
  font-size: 1.1rem;
  font-weight: 100;
  line-height: 25px;
  letter-spacing: 0.1rem;
  margin-bottom: 2.5rem;
`

const StyledSeparator = styled(Separator)`
  margin: 0.5rem 4.5rem 1.5rem;
`

const Realizations = () => {
  const intl = useIntl()
  const locale = intl.locale !== 'pl' ? `/${intl.locale}` : ''

  return (
    <Section>
      <Container>
        <H2>{intl.formatMessage({ id: 'realizations_title' })}</H2>
        <StyledSeparator />
        <Paragraph>
          {intl.formatMessage({ id: 'realizations_content' })}
        </Paragraph>
        <Button to={`${locale}/realizacje`}>
          {intl.formatMessage({ id: 'realizations_button' })}
        </Button>
      </Container>
    </Section>
  )
}

export default Realizations
