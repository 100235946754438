import React from 'react'
import styled from 'styled-components'
import { Container, Section, H2, Separator } from './style'
import { useIntl } from 'gatsby-plugin-intl'

const StyledSection = styled(Section)`

  @media (min-width: 800px) {
    padding: 0 2.5rem 2.5rem 2.5rem;
  }
`

const Paragraph = styled.p`
  text-align: justify;
  font-size: 1.1rem;
  font-weight: 100;
  line-height: 25px;
  letter-spacing: 0.1rem;
`

const AboutUs = () => {
  const intl = useIntl()

  return (
    <StyledSection>
      <Container>
        <H2>{intl.formatMessage({ id: 'aboutus_title' })}</H2>
        <Separator />
        <Paragraph>{intl.formatMessage({ id: 'aboutus_content' })}</Paragraph>
      </Container>
    </StyledSection>
  )
}

export default AboutUs
