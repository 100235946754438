import React from 'react'
import styled from 'styled-components'
import { graphql, StaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Container, Button } from './style'
import { useIntl } from 'gatsby-plugin-intl'

const StyledHeader = styled.header`
  padding: 1rem 2.5rem;
  background-color: #fff;
  color: #000;
  
  @media (min-width: 800px) {
    padding: 5rem 2.5rem;
  }
`

const StyledContainer = styled(Container)`
  @media (min-width: 800px) {
    flex-direction: row;
  }
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 60%;
  
  @media (min-width: 800px) {
    padding-right: 1rem;
  }

  @media (min-width: 1200px) {
    flex-basis: 70%;
  }
`

const H1 = styled.h1`
  font-size: 1.75rem;
  font-weight: normal;
  line-height: 33px;
  margin-bottom: 3rem;
`

const ImageContainer = styled.div`
  display: grid;
  position: relative;
  flex-basis: 40%;

  @media (min-width: 1200px) {
    flex-basis: 30%;
  }
`

const Image = styled((props) => <GatsbyImage {...props} />)`
  border-radius: 0 30%;
  max-width: 100%;
  margin: 0 -1.75rem 0 0;
  grid-column: 1;
  grid-row: 1;
  z-index: 2;
  right: -2.5rem;

  @media (min-width: 800px) {
    right: inherit;
  }
`

const Header = () => {
  const intl = useIntl()

  const query = graphql`
    {
      file(relativePath: { eq: "header_img.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            transformOptions: { fit: COVER }
            layout: FULL_WIDTH
          )
        }
      }
    }
  `

  return (
    <StyledHeader>
      <StyledContainer>
        <StyledContent>
          <H1>{intl.formatMessage({ id: 'header_title' })}</H1>
          <Button to={`/realizacje`}>
            {intl.formatMessage({ id: 'header_button' })}
          </Button>
        </StyledContent>
      </StyledContainer>
    </StyledHeader>
  )
}

export default Header
