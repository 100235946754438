import React from 'react'
import Reset from '../components/reset'
import Navbar from '../components/navbar'
import Header from '../components/header'
import AboutUs from '../components/aboutus'
import Offer from '../components/offer'
import Realizations from '../components/realizations'
import Footer from '../components/footer'
import SubFooter from '../components/subfooter'
import Seo from '../components/seo'
import { useIntl } from 'gatsby-plugin-intl'

const IndexPage = () => {
  const intl = useIntl()
  const locale = intl.locale !== 'pl' ? `${intl.locale}` : 'pl'

  return (
    <React.Fragment>
      <Seo
        title={intl.formatMessage({ id: 'site_title' })}
        description={intl.formatMessage({ id: 'site_description' })}
        keywords={intl.formatMessage({ id: 'site_keywords' })}
        lang={locale}
      />
      <Reset />
      <>
        <Navbar activepage="home" />
        <Header />
        <main>
          <AboutUs />
          <Offer />
          <Realizations />
        </main>
        <SubFooter />
        <Footer />
      </>
    </React.Fragment>
  )
}

export default IndexPage
